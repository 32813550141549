<template>
  <section>
    <h2 class="uppercase pl-4 md:pl-28 pt-4 text-gray-600">
      Sie sind hier / <a style="color: #f27405" home>Beroma Gmbh</a> /
      Pergolamarkise / Q.Bus <br />
    </h2>
    <article class="container mx-auto pl-2 pt-2 lg:p-32">
      <h2 class="text-4xl md:text-6xl pb-10" style="color: #f27405">
        Der Q.bus
      </h2>
      <div class="grid grid-cols-2">
        <div class="col-span-full">
          <iframe
            class="w-full"
            width="700"
            height="600"
            src="https://www.youtube.com/embed/jc3wvjYYqbo"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
            alt="qbus video"
          ></iframe>
        </div>

        <div class="col-span-full my-10">
          <h3 class="text-2xl pl-5 py-5 md:pb-3" style="color: #033859">
            Die unglaublich flexible Beschattung
          </h3>
          <p class="px-5 w-full md:pl-10 md:pb-3">
            Die Q.bus Anlagen bestechen durch ihre Eleganz und ihre
            zurückhaltendes kubi-sches Design. Die Aluminiumprofile wurden so
            gestaltet, dass die Konstruktion leicht und harmonisch wirkt.
            Besonders kommen die pulverbeschichteten Oberflächen in einer der
            LEINER LOUNGE FARBEN zur Geltung. Mit einem Q.bus wird jede Terrasse
            zu einem ele ganten, textilen Raum. <br /><br />
            Die horizontale Beschattung lässt sich im vorderen Bereich durch ein
            integriertes Tuch um ca. 100 cm absenken. Hierdurch lässt sich der
            Schatten dem Stand der Son-ne anpassen. Auch leichter Regen kann so
            ablaufen. Beide Tücher können unabhängig ein- und ausgefahren
            werden. Auf Wunsch bieten seitliche Senkrechtbeschattungen auch
            Schutz vor neugierigen Blicken, seitli-cher Sonne und Wind.
          </p>
        </div>
        <div class="py-6 col-span-full">
          <CoolLightBox :items="items" :index="index" @close="index = null">
          </CoolLightBox>
          <div
            class="overflow-x-scroll flex text-gray-600 mt-1 border shadow-sm gap-1 cursor-pointer w-full max-h-40"
          >
            <img
              class="image"
              v-for="(image, imageIndex) in items"
              :key="imageIndex"
              @click="index = imageIndex"
              :src="image.src"
              :alt="image.alt"
              :style="{ backgroundImage: 'url(' + image + ')' }"
            />
          </div>
        </div>
        <div class="mt-16 col-span-full">
          <p class="w-full px-5 lg:text-center">
            Freistehend ist der Q.bus | Q.bus SZ funktional und nutzt die Fläche
            optimal aus; mehrere Anlagen lassen sich zu einer großflächigen
            Beschattung kombinieren. Durch eine zusätzliche Ausstattung mit
            Seiten- oder Senkrechtmarkisen entsteht ein neuer textiler,
            flexibler Lebensraum. Die Dachbeschattung lässt sich durch ein in
            das Gestell integriertes Tuch einseitig um ca. 100 cm schräg
            absenken. Dadurch wird der Schattenbereich individuell regulierbar
            und leichter Regen kann ablaufen. Jedes Tuch kann unabhängig
            voneinander aus- und eingefahren werden.
          </p>
        </div>
      </div>
      <div class="p-4">
        <h3 class="text-4xl pt-10 pb-3" style="color: #033859">Eckdaten</h3>
        <tabs :mode="mode">
          <tab title="Allgemein">
            <p class="text-lg">
              Einzigartig sind die Q.bus Systeme auch durch ihre fast
              unbe-grenzten Montagemöglichkeiten. Ob als Einzelanlage oder als
              Kombination mehrerer Elemente lässt sich der Q.bus an die
              bauseitige Terrassensituation anpassen. Egal, ob dies eine
              Übereckterrasse oder ein Anschluss an eine Mauer ist. Die
              Möglichkeiten sind so vielfältig. Lassen Sie sich von Uns beraten!
            </p>
          </tab>
          <tab title="Ausstattung">
            <p class="text-lg">
              Ausgestattet mit Seiten- oder Senkrecht-markisen erstellen Sie
              Ihren textilen Raum. Ob privat genutzt oder in der Gas- tronomie,
              die Q.bus Anlagen machen immer eine gute Figur.
              <br /><br />
              Die Anlagen können komplett freistehend oder an die Wand montiert
              werden. Mehrere Anlagen lassen sich miteinan-der verbinden und
              auch Eck lösungen sind realisierbar. Im Vergleich zum Q.bus
              bie-tet der Q.bus SZ deutlich mehr Windstabi-lität. Das
              horizontale Markisentuch wird seitlich in der Führungs schiene
              gehalten. Dadurch wird der Q.bus SZ zum wind-stabilen
              Sonnenschutz.
            </p>
          </tab>
        </tabs>
      </div>
    </article>
  </section>
</template>
<script>
import Tab from "../components/Tab";
import Tabs from "../components/Tabs";
import CoolLightBox from "vue-cool-lightbox";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";

export default {
  components: {
    Tab,
    Tabs,
    CoolLightBox,
  },
  data: function () {
    return {
      items: [
        {
          src: require("../assets/img/leiner/qbar/header-qbus02.webp"),
          alt: "pergolamarkise leiner qbar",
        },
        {
          src: require("../assets/img/leiner/qbar/header-qbus03.webp"),
          alt: "pergolamarkise leiner qbar seitliche sicht",
        },
        {
          src: require("../assets/img/leiner/qbar/header-qbus04.webp"),
          alt: "pergolamarkise rot",
        },
        {
          src: require("../assets/img/leiner/qbar/header-qbus05.webp"),
          alt: "pergolamarkise weiß seitliche sicht",
        },
        {
          src: require("../assets/img/leiner/qbar/header-qbus06.webp"),
          al: "pergolamarkise auf zwei seiten des hauses",
        },
        {
          src: require("../assets/img/leiner/qbar/header-qbus07.webp"),
          alt: "pergolamarkise gartenansicht",
        },
        {
          src: require("../assets/img/leiner/qbar/header-qbus08.webp"),
          alt: "pergolamarkise sonne",
        },
      ],
      index: null,
    };
  },
};
</script>
<style>
.image {
  min-width: 20rem;
}
</style>
